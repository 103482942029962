<template>
  <Teleport to="body">
    <modal :show="trigger_modal" :class="props.source">
      <div class="modal__close" @click.prevent="$emit('close')">
        <icon-close />
      </div>
      <template #header>
        <div class="modal__header">
          <div class="modal__title">
            <icon-exclamation />
            <h4>
              You're about to delete list
              {{ props.list.name }}.
            </h4>
          </div>
          <p>
            Warning: You’re about to delete List {{ props.list.name }}. This action cannot be
            undone - please confirm if you would like to delete this list below.
          </p>
        </div>
      </template>
      <template #body>
        <div class="modal__body">
          <div>
            <button
              @click="emit('close')"
              class="btn__base btn__base--outline"
              :disabled="data.updating"
            >
              Cancel
            </button>
            <button
              @click="deleteList"
              class="btn__base btn__base--dark"
              :disabled="data.updating"
            >
              <template v-if="data.validation.success">
                <IconCheck />
              </template>
              Delete
            </button>
          </div>
          <div class="modal__validation">
            <p v-if="data.validation.error" class="small">
              There was an error. Please try again soon.
            </p>
          </div>
        </div>
      </template>
    </modal>
  </Teleport>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useCustomerStore } from '../../../stores/customer';
import Modal from '../Modal.vue';
import IconClose from '../icons/IconClose.vue';
import IconCheck from '../icons/IconCheck.vue';
import IconExclamation from '../icons/IconExclamation.vue';
import axios from 'axios';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  list: {
    type: Object,
    required: true,
  },
  trigger_modal: {
    type: Boolean,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const useCustomer = useCustomerStore();

const {
  PS,
  localStorage: ls,
} = globalThis;

const emit = defineEmits(['close', 'deleted-list']);

const data = reactive({
  updating: false,
  validation: {
    success: false,
    error: false,
  },
});

const deleteList = async () => {
  data.updating = true;
  const config = {
    baseURL: '/a/ps',
    method: 'delete',
    url: `${props.customer.id}/wishlist/${props.list.id}`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    }
  };
  try {
    const res = await axios(config);
    data.validation.success = true;
    setTimeout(() => {
      data.validation.success = false;
      emit('deleted-list');
    }, 1500);
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    } else {
      this.validation.error = true;
    }
    throw err;
  }
  data.updating = false;
};

</script>
