<template>
  <Teleport to="body">
    <modal :show="trigger_modal" :class="props.source">
      <template #header>
        <div class="modal__header">
          <p>
            Please enter a new name for
            {{ props.list.name }}
            below.
          </p>
          <div class="modal__close" @click.prevent="$emit('close')">
            <icon-close />
          </div>
        </div>
      </template>
      <template #body>
        <div class="modal__body">
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            @invalid-submit="onInvalidSubmit"
          >
            <div>
              <Field
                as="input"
                v-model="data.listName"
                type="text"
                name="listName"
                placeholder="List name"
              />
              <button
                class="btn__base btn__base--dark"
                :disabled="data.updating || data.disabled"
                type="submit"
              >
                <template v-if="data.validation.success">
                  <IconCheck />
                </template>
                Save
              </button>
            </div>
            <ErrorMessage name="listName" />
          </Form>
          <div class="modal__validation">
            <p v-if="data.validation.error" class="small">
              There was an error. Please try again soon.
            </p>
          </div>
        </div>
      </template>
    </modal>
  </Teleport>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useCustomerStore } from '../../../stores/customer';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import axios from 'axios';
import Modal from './../Modal.vue';
import IconClose from './../icons/IconClose.vue';
import IconCheck from './../icons/IconCheck.vue';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  list: {
    type: Object,
    required: true,
  },
  trigger_modal: {
    type: Boolean,
    required: true,
  },
  source: {
    type: String,
    required: false,
  },
});

const useCustomer = useCustomerStore();

const { PS, localStorage: ls } = globalThis;

const emit = defineEmits(['close', 'edited-list-name']);

const schema = Yup.object().shape({
  listName: Yup.string().test('len', 'A List Name is required and must be less than 200 characters.', (val) => val.length >= 2 && val.length <= 200),
});

const data = reactive({
  disabled: false,
  updating: false,
  listName: '',
  updatedListName: null,
  validation: {
    success: false,
    error: false,
  },
});

const onSubmit = async () => {
  data.updating = true;
  const config = {
    baseURL: '/a/ps',
    method: 'post',
    url: `${props.customer.id}/wishlist/${props.list.id}/name`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
    data: JSON.stringify({
      name: data.listName,
    }),
  };
  try {
    const res = await axios(config);
    const listName = await res.data.data;
    data.updatedListName = listName;
    data.validation.success = true;
    setTimeout(() => {
      data.validation.success = false;
      emit('edited-list-name');
    }, 1500);
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    } else {
      this.validation.error = true;
    }
    throw err;
  }
  data.updating = false;
};

const onInvalidSubmit = () => {
  data.disabled = true;
  setTimeout(() => {
    data.disabled = false;
  }, 1500);
};
</script>

<style scoped lang="scss">
form {
  span {
    font-size: 12px;
    display: block;
    color: #d90606;
    margin: 5px 0 10px 0;
  }
}
</style>
