<template>
  <Loading v-if="!data.mounted || !data.lists" size="small" />
  <div v-else class="order__lists">
    <div class="order__lists__header">
      <h3>Order Lists</h3>
    </div>
    <div class="order__lists__search__filter">
      <div class="order__lists__search__container">
        <input
          v-model="data.searchQuery"
          placeholder="Search List Name"
          type="search"
        />
        <button ref="submit" type="submit">
          <icon-search />
        </button>
      </div>
      <div class="order__lists__filter__container">
        <div
          :class="[
            'order__lists__filter',
            { 'order__lists__filter--active': data.isFilterBy },
          ]"
          @click="data.isFilterBy = !data.isFilterBy"
        >
          <p class="small cursor-pointer">
            Sort by: {{ data.sortByFilter.name }}
          </p>
        </div>
        <ul>
          <transition-expand>
            <ul v-if="data.isFilterBy">
              <li
                @click="updateSortByFilter(filter)"
                v-for="filter in sortByFilters"
                :key="filter.id"
              >
                <p
                  v-if="data.sortByFilter.id !== filter.id"
                  class="small cursor-pointer"
                >
                  {{ filter.name }}
                </p>
              </li>
            </ul>
          </transition-expand>
        </ul>
      </div>
    </div>
    <div class="order__lists__lists">
      <template v-if="Object.keys(data.lists).length !== 0">
        <hr />
        <div
          v-for="list in data.searchQuery !== ''
            ? queriedLists
            : paginatedLists"
          :key="list.id"
          class="order__lists__list"
        >
          <div>
            <h4 v-html="list.name"></h4>
            <div class="order__lists__details">
              <p class="light">
                Created: {{ formatTimestamp(list.created_at) }}
              </p>
              <p class="light">Items: {{ list.items_num }}</p>
            </div>
          </div>
          <div class="order__lists__action">
            <button
              @click="handleListDetail(list)"
              class="btn__base btn__base--dark"
            >
              View List
            </button>
            <button @click="addToCart(list)" class="btn__base btn__base--light">
              <icon-plus />
              Add to cart
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="accent">You currently have no lists.</p>
      </template>
    </div>
    <nav
      aria-label="Pagination"
      class="pagination__wrap"
      role="navigation"
      v-if="numberOfPages > 1 && data.searchQuery === ''"
    >
      <paginate
        :v-model="data.currentPage"
        :page-count="numberOfPages"
        :page-range="3"
        :margin-pages="2"
        :click-handler="handlePaginationChange"
        :prev-text="''"
        :next-text="''"
        :container-class="'pagination'"
        :page-class="'page'"
      >
      </paginate>
    </nav>
    <!-- Modals -->
    <add-to-cart-modal
      v-if="data.selectedList"
      :customer="props.customer"
      :list="data.selectedList"
      :trigger_modal="data.addToCartModal"
      @close="data.addToCartModal = false"
      source="add-to-cart"
      :variants="false"
    />
  </div>
</template>

<script setup>
import { reactive, onBeforeMount, onMounted, computed } from 'vue';
import Paginate from 'vuejs-paginate-next';
import { useCustomerStore } from '../../stores/customer';
import { formatTimestamp } from '../../utils/helpers';
import axios from 'axios';
import AddToCartModal from './modals/addToCartModal.vue';
import Loading from './Loading.vue';
import IconSearch from './icons/IconSearch.vue';
import IconPlus from './icons/IconPlus.vue';
import TransitionExpand from './transitions/TransitionExpand.vue';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['list-payload']);

const useCustomer = useCustomerStore();

const {
  PS,
  localStorage: ls,
} = globalThis;

const data = reactive({
  searchQuery: '',
  isFilterBy: false,
  sortByFilter: {
    id: 1,
    name: 'Newest to oldest',
  },
  mounted: false,
  lists: null,
  selectedList: null,
  currentPage: 1,
  listsPerPage: 6,
  addToCartModal: false,
});

const queriedLists = computed(() => {
  return data.lists.data.filter((list) => {
    return (
      list.name.toLowerCase().indexOf(data.searchQuery.toLowerCase()) != -1
    );
  });
});

const handleListDetail = (list) => {
  emit('list-payload', list);
};

const addToCart = (list) => {
  data.selectedList = list;
  data.addToCartModal = true;
};

const sortByFilters = [
  {
    id: 1,
    name: 'Newest to oldeset',
  },
  {
    id: 2,
    name: 'Name',
  },
];

const updateSortByFilter = (filter) => {
  data.sortByFilter = filter;
  //sort by Newest to oldest
  if (filter.id === 1) {
    data.lists.data.sort((a, b) => (a.created_at < b.created_at ? 1 : -1));
  }
  //sort by Name
  if (filter.id === 2) {
    data.lists.data.sort((a, b) => (a.name > b.name ? 1 : -1));
  }
};

const fetchOrderLists = async () => {
  const config = {
    baseURL: '/a/ps',
    method: 'get',
    url: `${props.customer.id}/wishlist/list`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
  };
  try {
    const res = await axios(config);
    const lists = await res.data.data;
    data.lists = lists;
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    }
    throw err;
  }
};

const setCurrentPage = (number) => {
  data.currentPage = number;
};

const numberOfPages = computed(() => {
  return Math.ceil(data.lists.data.length / data.listsPerPage);
});

const paginatedLists = computed(() =>
  data.lists.data.slice(
    (data.currentPage - 1) * data.listsPerPage,
    data.currentPage * data.listsPerPage
  )
);

const handlePaginationChange = (pageNum) => {
  setCurrentPage(pageNum);
};

onBeforeMount(() => {
  fetchOrderLists();
});

onMounted(() => {
  data.mounted = true;
});
</script>

<style scoped>
.pagination__wrap {
  margin-top: 35px;
  justify-content: flex-start;
}
</style>
