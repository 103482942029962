<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5114 1.05133C15.1629 1.70286 15.1629 2.73939 14.5114 3.39092L13.6229 4.27937L10.7206 1.3771L11.6091 0.488648C12.2606 -0.162883 13.2971 -0.162883 13.9487 0.488648L14.5114 1.05133ZM5.09378 7.00395L10.0395 2.05824L12.9418 4.96051L7.99605 9.90622C7.81836 10.0839 7.58144 10.232 7.34452 10.3208L4.70879 11.1797C4.44225 11.2685 4.17572 11.2093 3.99803 11.002C3.79072 10.8243 3.73149 10.5281 3.82034 10.2912L4.67917 7.65548C4.76802 7.41856 4.91609 7.18164 5.09378 7.00395ZM5.68608 1.73248C6.18954 1.73248 6.63376 2.1767 6.63376 2.68016C6.63376 3.21323 6.18954 3.62784 5.68608 3.62784H2.84304C2.30997 3.62784 1.89536 4.07206 1.89536 4.57552V12.157C1.89536 12.69 2.30997 13.1046 2.84304 13.1046H10.4245C10.9279 13.1046 11.3722 12.69 11.3722 12.157V9.31392C11.3722 8.81046 11.7868 8.36624 12.3198 8.36624C12.8233 8.36624 13.2675 8.81046 13.2675 9.31392V12.157C13.2675 13.7266 11.9941 15 10.4245 15H2.84304C1.24383 15 0 13.7266 0 12.157V4.57552C0 3.00592 1.24383 1.73248 2.84304 1.73248H5.68608Z"
      fill="#303030"
    />
  </svg>
</template>
