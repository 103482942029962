<template>
  <div class="account">
    <main class="wrapper__lg">
      <template v-if="data.accountState === '/account/order/lists'">
        <order-lists :customer="customer" @list-payload="handleListDetail" />
      </template>
      <template v-if="data.accountState === '/account/order/list/detail'">
        <order-list-detail
          :customer="customer"
          :list="data.selectedList"
          @update-account-state="updateAccountState('/account/order/lists')"
        />
      </template>
    </main>
  </div>
</template>

<script setup>
import { reactive, onMounted, onBeforeMount, computed } from 'vue';
import { useCustomerStore } from '../../stores/customer';
import OrderLists from './OrderLists.vue';
import OrderListDetail from './OrderListDetail.vue';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
});

const { PS, localStorage: ls } = globalThis;

const useCustomer = useCustomerStore();

const data = reactive({
  loading: false,
  accountState: '/account/order/lists',
  selectedOrder: null,
  selectedList: null,
  customerMetafields: null,
});

const updateAccountState = (pathname, async = false) => {
  data.accountState = pathname;
};

const handleListDetail = (value) => {
  data.selectedList = value;
  updateAccountState('/account/order/list/detail');
};

const handleUrlParams = () => {
  //return to account details if the email subscribe is actioned
  if (window.location.href.indexOf('form_type') != -1) {
    data.accountState = '/account/details';
    console.log(data.accountState);
    return;
  }
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  let accountState = urlParams.get('account_state');
  data.accountState = accountState;
  console.log(data.accountState);
};

onMounted(() => {
  if (window.location.search) {
    handleUrlParams();
  }
});

onBeforeMount(() => {
  useCustomer.state.id = props.customer.id;
  useCustomer.psAppToken(props.customer.id);
});
</script>
