<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 7.5C15 3.3871 11.6129 0 7.5 0C3.35685 0 0 3.3871 0 7.5C0 11.6431 3.35685 15 7.5 15C11.6129 15 15 11.6431 15 7.5ZM7.5 9.0121C8.25605 9.0121 8.89113 9.64718 8.89113 10.4032C8.89113 11.1895 8.25605 11.7944 7.5 11.7944C6.71371 11.7944 6.10887 11.1895 6.10887 10.4032C6.10887 9.64718 6.71371 9.0121 7.5 9.0121ZM6.16935 4.02218C6.13911 3.81048 6.32056 3.62903 6.53226 3.62903H8.4375C8.64919 3.62903 8.83064 3.81048 8.8004 4.02218L8.58871 8.13508C8.55847 8.34677 8.40726 8.46774 8.22581 8.46774H6.74395C6.5625 8.46774 6.41129 8.34677 6.38105 8.13508L6.16935 4.02218Z"
      :fill="props.fill"
    />
  </svg>
</template>

<script setup>

const props = defineProps({
  fill: {
    type: String,
    required: false,
    default: '#D90606'
  },
});

</script>
