<template>
  <Loading v-if="!data.mounted || !data.list || data.loading" size="small" />
  <div v-else class="order__list__detail">
    <div class="order__list__detail__header">
      <div class="order__list__detail__title">
        <h3 v-html="data.list.name"></h3>
        <div @click="data.editListNameModal = true" class="cursor-pointer">
          <icon-edit />
        </div>
      </div>
      <div class="order__list__detail__summary">
        <div>
          <p class="light">
            Created:
            {{ formatTimestamp(data.list.created_at) }}
          </p>
          <p class="light">Items: {{ data.list.items_num }}</p>
          <p class="accent">Total price (inc GST): {{ money(totalPrice) }}</p>
        </div>
        <div>
          <button
            v-if="data.list.items_num > 0 && totalInventory"
            @click="data.addToCartModal = true"
            class="btn__base btn__base--dark"
          >
            <icon-plus stroke="#ffffff" />
            Add to cart
          </button>
          <button
            @click="data.deleteListModal = true"
            class="btn__base btn__base--outline"
          >
            <icon-trash />
            Delete List
          </button>
          <button
            v-if="data.updatedPayload"
            @click="saveChanges"
            :disabled="data.listUpdating"
            class="btn__base btn__base--light order__list__detail__save"
          >
            <template v-if="data.listSaved">
              <IconCheck />
              Saved
            </template>
            <template v-else> Save changes </template>
          </button>
        </div>
      </div>
    </div>
    <div class="order__list__detail__line__items">
      <template v-if="data.list && data.list.items_num > 0">
        <hr />
        <order-list-detail-item
          v-for="item in paginatedItems"
          :key="item.id"
          :item="item"
          :customer="props.customer"
          :list_saved="data.listSaved"
          @update-variants="updateVariants"
          @update-item="updateItem"
          @remove-line-item="removeLineItem"
          @hydrate-list="fetchListDetail"
        />
      </template>
      <template v-else>
        <p class="accent">You currently have no products in your list.</p>
      </template>
    </div>
    <div class="order__list__detail__footer">
      <button
        v-if="data.updatedPayload"
        @click="saveChanges"
        :disabled="data.listUpdating"
        class="btn__base btn__base--light order__list__detail__save"
      >
        <template v-if="data.listSaved">
          <IconCheck />
          Saved
        </template>
        <template v-else> Save changes </template>
      </button>
      <nav
        aria-label="Pagination"
        class="pagination__wrap"
        role="navigation"
        v-if="numberOfPages > 1"
      >
        <paginate
          :v-model="data.currentPage"
          :page-count="numberOfPages"
          :page-range="3"
          :margin-pages="2"
          :click-handler="handlePaginationChange"
          :prev-text="''"
          :next-text="''"
          :container-class="'pagination'"
          :page-class="'page'"
        >
        </paginate>
      </nav>
      <div @click="emit('update-account-state')">
        <p class="accent underline">Back to all lists</p>
      </div>
    </div>
    <!-- Modals -->
    <edit-list-name-modal
      :customer="props.customer"
      :list="data.list"
      :trigger_modal="data.editListNameModal"
      @close="data.editListNameModal = false"
      @edited-list-name="editedListName"
      source="edit-list-name"
    />
    <delete-list-modal
      :customer="props.customer"
      :list="data.list"
      :trigger_modal="data.deleteListModal"
      @close="data.deleteListModal = false"
      @deleted-list="deletedList"
      source="delete-list"
    />
    <add-to-cart-modal
      :customer="props.customer"
      :list="data.list"
      :trigger_modal="data.addToCartModal"
      @close="data.addToCartModal = false"
      source="add-to-cart"
      :variants="true"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  onBeforeMount,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  computed,
} from 'vue';
import Paginate from 'vuejs-paginate-next';
import { useCustomerStore } from '../../stores/customer';
import { formatTimestamp } from '../../utils/helpers';
import axios from 'axios';
import { money } from '../../utils/money';
import { windowRedirect } from '../../utils/helpers';
import Loading from './Loading.vue';
import OrderListDetailItem from './OrderListDetailItem.vue';
import EditListNameModal from './modals/EditListNameModal.vue';
import DeleteListModal from './modals/DeleteListModal.vue';
import addToCartModal from './modals/addToCartModal.vue';
import IconEdit from './icons/IconEdit.vue';
import IconCheck from './icons/IconCheck.vue';
import IconPlus from './icons/IconPlus.vue';
import IconTrash from './icons/IconTrash.vue';

const props = defineProps({
  customer: {
    type: Object,
    required: true,
  },
  list: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const useCustomer = useCustomerStore();

const { PS, localStorage: ls } = globalThis;

const emit = defineEmits(['update-account-state']);

const data = reactive({
  loading: false,
  list: null,
  mounted: false,
  updatedPayload: false,
  editListNameModal: false,
  deleteListModal: false,
  addToCartModal: false,
  listUpdating: false,
  listSaved: false,
  currentPage: 1,
  itemsPerPage: 6,
  urlParams: {
    verified: false,
    customerId: null,
    listId: null,
  },
});

const totalPrice = computed(() => {
  let price = 0;
  data.list.items.forEach((item) => {
    price = price + item.subtotal;
  });
  return price;
});

const totalInventory = computed(() => {
  let inventory = 0;
  data.list.items.forEach((item) => {
    if(item.variant) {
      inventory = inventory + item.variant.inventory_quantity;
    }

  });
  return inventory > 0 ? true : false;
});

const updateVariants = (value) => {};

const updateItem = (value) => {
  data.updatedPayload = true;
};

const removeLineItem = (value) => {
  data.list.items = data.list.items.filter((item) => {
    return item.vid != value.vid;
  });
  data.updatedPayload = true;
};

const saveChanges = async () => {
  data.listUpdating = true;
  let payload = [...data.list.items];
  payload.forEach((item) => {
    delete item.variant;
    delete item.subtotal;
  });

  const config = {
    baseURL: '/a/ps',
    method: 'post',
    url: data.urlParams.verified
      ? `${data.urlParams.customerId}/wishlist/${data.urlParams.listId}/items`
      : `${props.customer.id}/wishlist/${props.list.id}/items`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
    data: JSON.stringify({
      items: payload,
    }),
  };
  try {
    const res = await axios(config);
    const list = await res.data;
    data.list = list.data;
    data.listSaved = true;
    setTimeout(() => {
      data.updatedPayload = false;
      data.listSaved = false;
    }, 1500);
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
    }
    throw err;
  }
  data.listUpdating = false;
};

const editedListName = () => {
  data.editListNameModal = false;
  fetchListDetail();
};

const deletedList = () => {
  data.deleteListModal = false;
  windowRedirect('/pages/order-lists');
};

const fetchListDetail = async () => {
  data.loading = true;
  const config = {
    baseURL: '/a/ps',
    method: 'get',
    url: data.urlParams.verified
      ? `${data.urlParams.customerId}/wishlist/${data.urlParams.listId}`
      : `${props.customer.id}/wishlist/${props.list.id}`,
    headers: {
      'Content-Type': 'application/json',
      'Custom-Token': ls.getItem('psAppToken'),
    },
  };
  try {
    const res = await axios(config);
    data.list = await res.data.data;
    await data.list.items.forEach(async (item) => {
      const config = {
        method: 'get',
        url: `/products/${item.handle}?view=append`,
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const res = await axios(config);
      const listProductData = await res.data;
      if (listProductData.variants) {
        item.variant = listProductData.variants;
      }
    });
  } catch (err) {
    if (err.response.status === 400) {
      await useCustomer.psAppToken(props.customer.id);
      await fetchListDetail();
    }
    throw err;
  }
  if (data.urlParams.verified) {
    window.history.replaceState({}, document.title, '/' + 'pages/order-lists');
  }
  data.loading = false;
};

const appendListProductData = async (listProductData) => {
  console.log(listProductData)
}

const setCurrentPage = (number) => {
  data.currentPage = number;
};

const numberOfPages = computed(() => {
  return Math.ceil(data.list.items.length / data.itemsPerPage);
});

const paginatedItems = computed(() =>
  data.list.items.slice(
    (data.currentPage - 1) * data.itemsPerPage,
    data.currentPage * data.itemsPerPage
  )
);

const handlePaginationChange = (pageNum) => {
  setCurrentPage(pageNum);
};

const handleUrlParams = () => {
  data.urlParams.verified = true;
  let queryString = window.location.search;
  let urlParams = new URLSearchParams(queryString);
  data.urlParams.customerId = urlParams.get('customer_id');
  data.urlParams.listId = urlParams.get('list_id');
  fetchListDetail();
};

onMounted(() => {
  data.mounted = true;
});

onBeforeMount(() => {
  if (window.location.search) {
    handleUrlParams();
  } else {
    fetchListDetail();
  }
});
</script>

<style scoped>
.pagination__wrap {
  margin-top: 0;
  justify-content: flex-start;
}
</style>
